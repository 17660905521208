<template>
  <base-section
    id="k-d-a-consult-company-logo"
    class="background-tech-list"
    top-inner-shadow
    space="20"
  >
    <div id="container" :style="{ height: `${g_bLowerBr ? '80px' : '125px'}`, maxHeight: '300px' }">
      <div class="photobanner">
        <img
          v-for="(item, idx) in m_arrCompanyLogo"
          :key="idx"
          :src="item"
          :style="{
            height: `${g_bLowerBr ? '80px' : '125px'}`,
            maxWidth: `${g_bLowerBr ? '150px' : '326px'}`,
            alignItems: 'center',
            maxHeight: '125px',
            objectFit: 'contain'
          }"
          alt=""
        />
      </div>
    </div>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';

export default {
  name: 'KDAConsultCompanyLogo',

  mixins: [BaseBreakpoint],

  data() {
    return {
      interval: 3000
    };
  },

  computed: {
    m_arrCompanyLogo() {
      const resultOriginal = [];
      const resultLoop = [];

      for (let i = 0; i < 8; i++) {
        resultOriginal.push(require(`@/assets/photos/consults-comp-logo-0${i + 1}.png`));
      }

      for (let i = 0; i < 8; i++) {
        resultLoop.push(require(`@/assets/photos/consults-comp-logo-0${i + 1}.png`));
      }

      return resultOriginal.concat(resultLoop);
    }
  }
};
</script>

<style scoped lang="scss">
@import 'src/styles/variables.scss';

#container {
  position: relative;
  overflow: hidden;
}

.photobanner {
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
  white-space: nowrap;
  animation: bannermove 15s linear infinite;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.photobanner img {
  margin: 0 1.5rem;
}

@keyframes bannermove {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-50%, 0);
  }
}
.background {
  background-color: #fbfbfb;
}

.img-container {
  overflow: hidden;
  position: relative;
  height: 100%;
  display: flex;

  img {
    &.right {
      height: 460px;
      width: 1000px;
    }

    &.top {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: relative;
    }
  }
}
</style>
