var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-section',{staticClass:"background-tech-list",attrs:{"id":"k-d-a-consult-company-logo","top-inner-shadow":"","space":"20"}},[_c('div',{style:({ height: `${_vm.g_bLowerBr ? '80px' : '125px'}`, maxHeight: '300px' }),attrs:{"id":"container"}},[_c('div',{staticClass:"photobanner"},_vm._l((_vm.m_arrCompanyLogo),function(item,idx){return _c('img',{key:idx,style:({
          height: `${_vm.g_bLowerBr ? '80px' : '125px'}`,
          maxWidth: `${_vm.g_bLowerBr ? '150px' : '326px'}`,
          alignItems: 'center',
          maxHeight: '125px',
          objectFit: 'contain'
        }),attrs:{"src":item,"alt":""}})}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }